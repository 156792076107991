$__primarycolor:#92EEC3;
$__primarycolorhover:#DEF8EC;
$__light-orange:#F4F0EC;
$__gray_500:#A5A5A5;
$__gray:#999999;
$__gray_100: #DBDBDB;

$__primary_100: #DEF8EC;
$__darkGreen: #82A695;
$__white:#fff;
$__black:#000;
$__light-goose:#E8EBED;
$__successcolor: #28C76F;
$__dangercolor: #f62d51;
$__warningcolor: #FF9900;


$__light__smokey-gray:#5E5873;
$__light__smokey-grays:#292D32;
$__light__vampire: #f1f1f1;
$__light__vampires:#FAFBFE;
$__light-goose-grays:#E9ECEF;
$__violet:#423B93;
$__secondarycolor:#212B36;
$__dark__light: #f5f5f5;
$__sandstone: #637381;
$__gray__dark: #637381;
$__light__gooses: #F8F8F8;