.search-component{
    height: 52px !important;
    input{
        background: $__white;
        box-shadow: 0px 4px 4px 0px #00000040;
    }
    input:focus{
        box-shadow: none;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2509803922);
        border: none !important;
        outline: none !important;
    }

    .dropdown-content{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background: white;
        z-index: 100000;
        position: relative;
        width: 100% !important;
        --tw-scale-x: 1 !important;
        top: 51px;
        min-height: 50px;
        padding: 18px 5px 20px 5px;
        .suggestion{
            li{
                a{
                    padding:10px;
                    &:hover{
                        border: none !important;
                        outline:  none !important;
                        box-shadow: none !important;
                        color: $__black;
                    }
                    &:active{
                        background: $__gray_100 !important;
                    }
                }
            }
        }
        
    }
}