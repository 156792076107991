.header {
    @include respond-below(custom991) {
        border-bottom: 1px solid $__light-goose;
        margin: 0px ;
        .current-page{
            display: $__none;
        }
    }

    .current-page{
       h1{
        font-size: 30px;
        font-weight: $__medium;
       }
       p{
        font-size: $__font__size__12;
        font-weight: $__light;
        color: $__gray;
       }
    }
    .dropdown-content{
        background: $__white;
    }
    padding: 0px 0px;
    height: 81px;
    background: $__primarycolor;
    background: $__white;
    z-index: 999;
    @include position($__fixed,0,0,null,0);

    .dropdown-menu {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
        margin-top: 10px !important;
        border-radius: 10px;
        width: 185px;
        @include respond-below(custom991) {
            transition: $__none;
        }
    }
    .header-left {
        float: $__left;
        height: 60px;
        position: $__relative;
        text-align: $__center;
        width: 260px;
        z-index: 1;
        @include margin-padding(null, 0 20px);
        @include transition(all 0.2s ease);
        @extend %display-flex;
        @extend %justify-content-between;
        @extend %align-items-center;
        border-right: 1px solid #DEE2E6;
        .logo {
            display: $__inline__block;
            line-height: 0;
            img{
                margin-top: 10px;
                height: 40px;
                object-fit: cover;
            }
            @include respond-below(custom991) {
                display: flex;
                justify-content: center;
                align-items: center;
                width: $__full__width;
                img{
                    height: 40px;
                    object-fit: cover;
                }
            }
        }
        .logo-small{
            display: $__none;
        }
        @include respond-below(custom991) {
            position: $__absolute;
            width:$__full__width;
        }
    }

    @include respond-below(custom991) {
       .header-left #toggle_btn {
            display: $__none;
        }
    }
    .page-title-box {
        float: $__left;
        height: 60px;
        margin-bottom: 0;
        @include margin-padding(null, 17px 20px);
        @include rounded(0);
        @include respond-below(custom991) {
            display: $__none;
        }
        a {
            color: $__primarycolor;
            font-size: $__font__size__11;
            font-weight: $__bold;
            border: 1px solid $__primarycolor;
            @include margin-padding(0, 5px);
            @include rounded(5px);
            &:hover{
                background: $__primarycolor;
                color: $__white;
            }
        }
    }
    .mobile_btn {
        display: $__none;
        float: $__left;
        @include respond-below(custom991) {
            color: $__primarycolor;
            font-size: $__font__size__24;
            height: 60px;
            line-height: 60px;
            width: 60px;
            z-index: 10;
            @include margin-padding(null, 0 20px);
            @include position($__absolute,0,null,null,0);
            display: $__block;
        }
       
    }
    .responsive-search {
        display: $__none;
        color: $__white;
        font-size: $__font__size__20;
        height: 60px;
        line-height: 60px;
        @include margin-padding(null, 0 15px);
        @include respond-below(custom991) {
            display: $__block;
        }
    }
    .top-nav-search {
        form {
            position: $__relative;
            width: 230px;
            @include respond-below(custom991) {
                display: $__none;
            }
            .form-control {
                background-color: rgba(255, 255, 255, 0.1);
                border: 1px solid rgba(255, 255, 255, 0.15);
                height: 40px;
                @include margin-padding(null, 10px 50px 10px 15px);
                @include rounded(50px);
                &::-webkit-input-placeholder {
                    color: rgba(255, 255, 255, 0.7);
                }
            }
            .btn {
                background-color: $__transparent;
                border-color: $__transparent;
                color: rgba(255, 255, 255, 0.7);
                min-height: 40px;
                @include position($__absolute,0,0,null,null);
            }
        }
        @include respond-below(custom991) {
            &.active{
                form {
                    display: $__block;
                    @include position($__absolute,null,null,null,0);
                    .form-control {
                        background-color: $__white;
                    }
                    .btn {
                        background-color: $__transparent;
                        border-color: $__transparent;
                        min-height: 40px;
                        @include position($__absolute,0,0,null,null);
                        @include margin-padding(null, 7px 15px);
                    }
                }
            }
        }
    }
    .userset {
        @extend %display-flex;
        @extend %align-items-center;
        .usernames{
            line-height: $__initial;
            text-align: $__right;
            padding-right: 10px;
            span {
                display: $__block;
                line-height: $__initial;
            }
        } 
    }
    .menu-drop-user{
        .dropdown-item {
            @extend %display-flex;
            @extend %align-items-center;
            padding: 7px 10px !important;
            svg {
                stroke-width: 1px;
                margin-right: 10px;
                width: 18px;
            }
            &:hover{
                background: $__gray_100;
                color: $__black;
                svg {
                    color: $__black;
                }
                border-radius: 10px;
            }
        }
        .logout{
            color: $__dangercolor;
        }
    }
    .has-arrow{
        .dropdown-toggle{
            @include respond-below(custom991) {
                > span{
                    &:nth-child(2) {
                        display: $__none;
                    }
                }
                &:after{
                    display: $__none !important;
                }
            }
        }
    }
    .profilename {
        .user-img {
            .status {
                bottom: 10px;
            }
        }
    }
    .user-img {
        display: $__inline__block;
        position: $__relative;
        img {
            width: 38px;
            @include rounded(50%);
        }
        .status {
            border: 2px solid $__white;
            height: 10px;
            width: 10px;
            @include margin-padding(0, null);
            @include position($__absolute,null,0,30px,null);
            @include rounded(50%);
            display: $__inline__block;
            background: $__successcolor;
        }
    }
    .mobile-user-menu {
        color: $__white;
        display: $__none;
        float: $__right;
        font-size: $__font__size__24;
        height: 60px;
        line-height: 60px;
        text-align: $__right;
        width: 60px;
        z-index: 10;
        @include position($__absolute,0,0,null,null);
        @include respond-below(custom991) {
            display: $__block;
        }
        summary{
            color: $__gray;
        }
        .dropdown-content{
            width: 137px;
            color: $__gray;
            border-radius: 10px;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            .dropdown-item {
                @extend %display-flex;
                @extend %align-items-center;
                padding: 7px 10px !important;
                svg {
                    stroke-width: 1px;
                    margin-right: 10px;
                    width: 18px;
                }
                &:hover{
                    background: $__gray_100;
                    color: $__black;
                    svg {
                        color: $__black;
                    }
                    border-radius: 10px;
                }
            }
            .logout{
                color: $__dangercolor;
            }
        }
    }
}
.logo-small img {
    width: 30px;
}






.bar-icon {
    display: $__inline__block;
    width: 31px;
    margin-top: 20px;
    span {
        background-color: $__gray;
        display: $__block;
        float: $__left;
        height: 3px;
        margin-bottom: 7px;
        width: 31px;
        border-radius: 2px;
        transition: all 0.5s;
        &:nth-child(2) {
            width: 16px;
        }
    }
}


.btn-profile-dropdown{
    background: none !important;
    box-shadow: none !important;
    border: none !important;
    .user-info {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 30px 0 0;
     
      .user-role {
        display: block;
        color: #AEAEAE;
        font-weight: $__bold;
        font-size: 12px;
        margin-top: 6px;
      }
      .user-letter {
        width: 40px;
        height: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-right: 10px;
        img{
            object-fit: cover;
            height: 100%;
            width: 100%;
         border-radius: 100%;
    
        }
      }
      .user-name {
        font-size: 15px;
        font-weight: $__bold;
        color: black;
        display: flex;
        align-items: center;
      }
      .user-name img {
        margin-left: 10px;
      }
    }
}

.user-menu {
    margin: 0;
    position: $__relative;
    padding-right: 15px;
    align-items: center;
    justify-content: end;
    display: flex;
    height: 100%;
    @include respond-below(custom991) {
        display: $__none;
    }
    > li {
        margin-left: 18px;
        svg {
            width: 18px;
            color: #67748E;            
        }
        a:hover{
            svg {
                color: $__primarycolor;
            }
        }
    }
    .dropdown-toggle{
        &::after {
            border: 0;
            display: none;
        }
    }
    .nav-searchinputs {
        margin-right: auto;
    }
    .nav-item-box > a {
        border-radius: 8px;
        width: 36px;
        height: 36px;
        display: $__flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &:hover{
            background: #EEF3F5;
        }
    }
  
}
