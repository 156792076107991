.dropdown-action{
    color: black !important;
    summary{
        background: none !important;
    }
    summary:hover, summary:focus{
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
    }
    .dropdown-content{
        background: $__white;
        border-radius: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        color: black !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

        .link-ancher{
            &:hover{
                color: black ;
            }
        }
    }

}