
.sidebar {
    background-color: $__transparent;
    border-right: 1px solid $__transparent;
    margin-top: 0;
    z-index: 1001;
    @include position($__fixed,60px,null,0,0);
    @include transition(all 0.2s ease);
    @extend %display-flex;
    border-right: 1px solid $__light-goose;
    width: 260px;
    @include respond-below(custom991) {
        margin-left: -575px;
        @include transition(all 0.4s ease);
        z-index: 1041;
        background: $__white;
    }

    .slimScrollDiv {
        width: 260px !important;
        overflow: $__visible !important;
        background: $__white;
        @include respond-below(custom575) {
            width: $__full__width !important;
        }
    }
    .sidebar-menu{
        @include margin-padding(null, 25px);
    }
    .sidebar-menu{
        > ul{
            > li{
                margin-bottom: 10px;
                svg {
                    width: 18px;
                    margin-right: 10px;
                }   
              
                &.submenu-open{
                    ul{
                        @include margin-padding(null, 10px 0);
                        display: block;
                       li{
                            a{
                               font-weight: 500;
                               font-size: $__font__size__15;
                               color: $__gray;
                               position: $__relative;
                               display: $__flex;
                               align-items: $__center;
                                margin-top: 18px;
                                justify-content: center;
                               @include margin-padding(null, 8px 15px);
                               &:hover{
                                   color:$__darkGreen;
                                   &:after{
                                    background: #1cc88a;
                                    border: 2px solid #FDB;
                                   }
                               }
                           }  
                       }
                       > li 
                       {
                        &.active a{
                            background: $__primary_100;
                            border-radius: 5px;
                            color: $__darkGreen;
                        }
                       }
                    }
                    .submenu-hdr {
                        font-weight: 500;
                        font-size: 14px;
                        color: #1B2950;
                        margin: 15px 0 0;
                    }
                    &:first-child {
                        h6 {
                            margin-top: 0;
                        }
                    }
                    .submenu {
                        ul {
                            display: none;
                            border: 0;
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }
         

        }
      
    }

    .sidemenu {
        a {
            width: $__full__width;
            font-weight: $__medium;
            display: $__block;
            @include rounded(5px);
            @include margin-padding( 0 0 10px, 10px);
            &:hover{
                color: $__white;
                svg{
                    color:$__white;
                }
            }
            svg {
                stroke-width: 1px;
            }
           
        }
    }
}
.header-left{    
        #toggle_btn{
            position: $__absolute;
            opacity: 1;
            background: $__primarycolor;
            right: -13px;
            border-radius: 50%;
            display: $__flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            color: #fff;
            cursor: pointer;
            padding: 4px;
            svg {
                transform: rotate(180deg);
            }    
            &:hover {
                background: $__primarycolorhover;
            }       
        }
        &.active svg {
            transform: rotate(0) !important;
        }
      
}

@include respond-above(custom991) {
   
    #toggle_btn {
       display: flex;
       align-items: center;
       justify-content: center;
        float: $__left;
        margin-left: 15px;
    }
}
.sidebarrightmenu{
    .sidebar-right{
        display: $__block;
    }
}
.slide-nav{
    .sidebar {
        margin-left: 0;
    }
}
.sidebar-overlay {
    display: $__none;
    height: $__full__height;
    width: $__full__width;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.6);
    @include position($__fixed,60px,null,null,0);
    &.opened {
        display: $__block;
    }
}
.nosidebar {
    .sidebar {
        left: -350px;
    }
    .page-wrapper{
        margin: 0;
    }
    #toggle_btn{
        svg{
            @include transform(rotate(180deg));
        }
    }
}
.active.subdrop ~ ul{
    display: $__block !important;
}


