@import "assets/scss/main";

@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 5px; /* width of the entire scrollbar */
  height: 5px; /* width of the entire scrollbar */
}

/* styles for the draggable part of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: darkgrey; /* color of the draggable part */
  border-radius: 10px; /* rounded corners for the draggable part */
}

/* styles for the part of the scrollbar track where the thumb isn't over */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the track */
  border-radius: 10px; /* rounded corners for the track */
}

form{
  width: 100%;
  margin-top: 20px;
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  label{
      font-size: $__font__size__16;
      font-weight:$__semibold;
      display: block;
  }
  input[type="text"], input[type="password"], input[type="number"], input[type="date"],textarea{
      width: 100%;
      border: none ;
      outline: none ;
      box-shadow: none;
      border-radius: 0px;
      background: $__white;
      border-bottom: 1px solid $__gray_500;
      padding-right: 35px;
  }
  .is-invalid{
      border-bottom: 1px solid red;
      transition: all 0.5s ease-in-out;
  }
  .valid{
      border-bottom: 1px solid $__primarycolor;
      transition: all 0.5s ease-in-out;
  }
  .invalid-feedback{
      color: red;
      font-size: $__font__size__14;
      font-weight: $__semibold;
  }

}

.customized-btn{
  background: $__primary_100 ;
  color: $__darkGreen;
  border: none;
  box-shadow: none;
  height: 40px;
  border-radius: 15px;
  font-size: $__font__size__14;
  font-weight: $__semibold;
}
.customized-btn:hover{
  background: $__primary_100 ;
  color: $__darkGreen;
}

.info-icon{
  border: none;
  box-shadow: none;
  height: 19px;
  opacity: 0.6;
}
