.aanvraag-details-page{
    .card{
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;

    }
    .card-type-2{
        .title{
            font-weight: 700 !important;
            font-size: 20px;
        }
        strong{
            font-weight: 500;
        }
        .left-items{
            div{
                margin-bottom: 10px;
            }
        }
        .right-items{
            div{
                margin-bottom: 10px;
            }
        }

        height: 480px;
        max-height: 480px;
    }

    .card-type-3{
        height: 170px;
        max-height: 170px ;
    }
}

.client-eia-aanvragen-page{

    form{
        button{
            background: $__primary_100 ;
            color: $__darkGreen;
            border: none;
            box-shadow: none;
            height: 60px;
            border-radius: 15px;
            font-size: $__font__size__16;
            font-weight: $__semibold;
        }
        .upload-button{
            height: 45px !important;
            font-size: $__font__size__13;
            background: rgb(240, 240, 240);
        }
        label{
            font-weight: 400 !important;
        }
        .file-input-accent::file-selector-button{
           background: #A5A5A5;
           border: none !important;
           color: #FFFFFF !important;
           font-weight: 300;
        }
        .kvk-nummer-btn{
            height: 30px;
            min-height: 30px;
            font-size: 13px;
            padding: 0px 10px !important;
            margin: 0px !important;
            border-radius: 10px;
            font-size: 12px;
        }
        .kvk-nummer-btn:hover{
            background: #A5A5A5;
        }
        .kvk-nummer-btn:disabled{
            background: transparent !important;
            display: flex;
        }
    }
    .details-card{
        font-weight: 500;
    }
}