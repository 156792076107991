#profile-modal, #create-user-modal, #view-customer-modal, #reject-reason-modal, #update-fees-modal, #update-limits-modal{
    .modal-box{
        background: $__white !important;
        border-radius: 40px !important;
        padding: 40px 40px 20px 40px;
        img{
            height: 45px;
        }
        .modal-title{
            font-size: $__font__size__18;
            font-weight: $__medium;
            color: $__secondarycolor;
            @include margin-padding(0 0 20px, null);
            @include respond-below(custom991) {
                font-size: $__font__size__14;
            }
        }

        .profile-pic{
             img{
                height: 150px;
                  width: 150px;
                 border-radius: 10px;
             }
        }

        .update-profile-form{
            button{
                background: $__primary_100 ;
                color: $__darkGreen;
                border: none;
                box-shadow: none;
                height: 60px;
                border-radius: 15px;
                font-size: $__font__size__16;
                font-weight: $__semibold;
                text-wrap: nowrap;
            }

        }
    }

    #view-customer-modal{
        input{
            border-bottom: none !important;
        }
    }
}

#reject-reason-modal{
    textarea{
        box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        border-radius: 10px;
    }
}