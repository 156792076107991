.sign-in-page, .forget-password-page, .reset-password-page{
    background: $__light-orange ;
    height: 100vh;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    .logo{
        img{
            height: auto;
            width: auto;
            max-width: auto;
            object-fit: contain;
        }
    }
    .sign-in-form{
        padding: 20px;
        width: 100%;
        max-width: 440px;
        h1{
            font-size: $__font__size__30;
            font-weight: $__semibold;
        }
        button{
            background: $__gray_500 ;
            color: white;
            border: none;
            box-shadow: none;
            height: 60px;
            border-radius: 15px;
            font-size: $__font__size__16;
            font-weight: $__semibold;
        }

        .password-toggle-btn{
            position: absolute;
            right: 13px;
            top: 31px;
            cursor: pointer;
            opacity: 0.7;
        }
        input{
            background: $__light-orange;
        }
    }

    .forget-password{
        width: 100%;
        text-align: right;
        margin-top: 10px;
        a{
            color: $__gray_500;
          font-size: $__font__size__16;
          font-weight: $__semibold;
          cursor: pointer;
        }
    }
}