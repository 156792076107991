.error-box{
    background: #ffff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

        text-align: center;
        h1{
            font-weight: 700;
            font-size: 100px !important;
            color: $__darkGreen;
            margin: 0;
        }
        h3{
            font-size: 24px;
            font-weight: 600;
            color: $__darkGreen;
            margin: 0;
        }
        p{
            font-size: 16px;
            color: $__darkGreen;
            margin: 0;
        }
       a{
        background: $__darkGreen;
            color: #fff;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            font-weight: 600;
            margin-top: 20px;
            cursor: pointer;
            border: none !important;
            transition: all 0.3s;
            &:hover{
                background: $__darkGreen;
                opacity: 0.9;
            }
       }
    }
