.admin-pages{
    .card{
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        margin-bottom: 0px !important;
    }
    .card-type-2{
        .title{
            font-weight: 600 !important;
        }
        .count{
            font-weight: 600;
        }
        .icon{
            padding: 5px;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            position: relative;
            span{
                font-size: 18px;
                position: absolute;
                top: 2px;
                left: 7px;
            }
        }
        strong{
            font-weight: 500;
        }
        .financieel{
            background: #DDF9E4 !important;
        }
    }
}